import { Carousel, CarouselSlide, FluidImage, StandardTile, StandardTileContents, StandardTileImage, StandardTileName, Link, Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'

export interface Props {
  data: Record<string, Item>
}
export interface Item {
  id: number,
  name: string,
  image: { url: string }
}

export const Fragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  const imageOnly = Object.values(props.data).filter(cat => cat.image)
  const firstTwentyCategories = imageOnly.slice(0, 20)

  useSwanStyleKeys(['core', 'carousel', 'standardTile'])

  return (
    <div>
      <Typography>Categories</Typography>
      <Carousel
        slidesToShow={8}
        skin='full'
        accessibleTextForPrevious='Go to previous slide'
        accessibleTextForNext='Go to next slide'
      >
        {
        firstTwentyCategories?.map((cobalt: any) => (
          <CarouselSlide key={cobalt?.id}>
            <StandardTile
              skin='circular'
            >
              <Link href='https://www.vistaprint.com' skin='unstyled'>
                <StandardTileImage>
                  <FluidImage src={cobalt?.image?.url} />
                </StandardTileImage>
                <StandardTileContents>
                  <StandardTileName>{cobalt?.name}</StandardTileName>
                </StandardTileContents>
              </Link>
            </StandardTile>
          </CarouselSlide>
        ))
      }
      </Carousel>
    </div>

  )
}
